/** 
 *   自营专区
 */
import { get } from '@/untils/js/axios.js'

// 获取分类显示
export const getMoobyClassify = id => get(`/special/type-list/${id}`)

// 获取分类显示下的商品
export const getClassifyGoods = params => get('/special/goods-list', params)

// 获取分类Banner
export const getMoobyBanner = id => get(`/special/banner-list/${id}`)


// 获取分类显示
export const getGiftClassify = id => get(`/special/type-list/${id}`)

// 获取分类显示下的商品
export const getGiftClassifyGoods = params => get('/special/goods-list', params)

// 获取分类Banner
export const getGiftBanner = id => get(`/special/banner-list/${id}`)

// 获取Color
export const getGiftColor = id => get(`/special/info/${id}`)

